import Vue from "vue";
import VueRouter from "vue-router";
import PublicLayout from '@/views/layouts/PublicLayout';

// Pages
import NotFound from '@/views/pages/errors/NotFound';
import Main from '@/views/pages/Main';
import Redeem from '@/views/pages/Redeem';
import Confirmed from '@/views/pages/Confirmed';
import Edit from '@/views/pages/Edit';

Vue.use(VueRouter);
const guardAuthService = async (to, from, next) => {
	try {
		let params = window.location.href.split('&');
		params = params.filter(p => {
			return p.includes('access_token=');
		})
		let gtoken = null;
		if(params && params.length) {
			gtoken = params[0].replace('access_token=', '');
		}
		let token = localStorage.getItem('token');
		setTimeout(() => {
			if(gtoken) {
				Vue.prototype.$service.login.googleLogin({ token: gtoken }).then(res => {
					Vue.prototype.$api.setToken(res.access_token, res.expires_in);
					next()
				}).catch(err => {
					console.error('ERR', err);
				})
			} else {
				if(token && token !== '') {
					next();
				} else {
					next({
						name: 'login.page'
					})
				}
			}
		}, 20)
		if ((token && token !== '') || gtoken) {
			next()
		} else {
			next({
				name: 'login.page',
			})
		}
	} catch (e) {
		next({
			name: 'login.page',
		})
	}
};

const routes = [
	{
		path: "/notfound",
		name: "nofound.page",
		component: NotFound,
	},
	{
		path: '/',
		name: 'main.page',
		component: Main,
	},
	{
		path: '/riscatta',
		name: 'redeem.page',
		component: Redeem
	},
	{
		path: '/conferma',
		name: 'confirmed.page',
		component: Confirmed
	},
	{
		path: '/modifica',
		name: 'modifica.page',
		component: Edit
	}
	// {
	// 	path: '*',
	// 	redirect: '/'
	// }
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
});

export default router;
