<template>
	<div class="landing">
		<span class="landing__logo">
			<img class="landing__logo__image" src="@/assets/images/mwdgroup.png" alt="" />
		</span>
		<section class="landing__intro landing__block">
			<span class="landing__intro__title">
				&#200; fantastico averti qui!
			</span>
			<span class="landing__intro__text">
				Grazie per aver partecipato alla nostra risottata natalizia
			</span>
			<figure class="landing__intro__media">
				<img  class="landing__intro__media__image" src="@/assets/images/intro-img.png" alt="" />
			</figure>
		</section>
		<section class="landing__dyk landing__block">
			<span class="landing__dyk__title">
				Sai cos'è una Digital Business Card?
			</span>
			<span class="landing__dyk__text">
				È l'evoluzione dei tradizionali biglietti da visita. Al suo interno è possibile inserire tutte le proprie informazioni di contatto, facilitando lo scambio rapido e digitale dei recapiti lavorativi.
			</span>
		</section>
		<section class="landing__vcard landing__block">
			<span class="landing__vcard__title">
				Qui puoi creare la tua Digital Business Card!
			</span>
			<figure class="landing__vcard__media">
				<img class="landing__vcard__media__image" src="@/assets/images/vcard.png" alt="" />
			</figure>
		</section>
		<section class="landing__ctas landing__block">
			<router-link :to="{ name: 'redeem.page', query: { id: code } }" class="btn filled">
				Inizia ora
			</router-link>
			<!-- <router-link to="" class="simple-link">
				Altre informazioni
			</router-link> -->
		</section>
		<span class="landing__logo small">
			<img class="landing__logo__image" src="@/assets/images/mwdgroup.png" alt="" />
		</span>
	</div>
</template>
<script>
export default {
	name: 'Landing',
	props: {
		code: {
			type: String,
			required: true,
		}
	},
	data() {
		return {

		}
	},
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/landing';
</style>
