<template>
	<svg id="icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g id="icons">
			<symbol id="icon--check" viewBox="0 0 50 50">
				<path d="M42.7 42.7C38 47.4 31.6 50 25 50s-13-2.6-17.7-7.3C2.6 38 0 31.6 0 25S2.6 12 7.3 7.3C12 2.6 18.4 0 25 0s13 2.6 17.7 7.3C47.4 12 50 18.4 50 25s-2.6 13-7.3 17.7zM40.2 9.8c-4-4-9.5-6.3-15.2-6.3-5.7 0-11.2 2.3-15.2 6.3S3.5 19.3 3.5 25c0 5.7 2.3 11.2 6.3 15.2s9.5 6.3 15.2 6.3c5.7 0 11.2-2.3 15.2-6.3s6.3-9.5 6.3-15.2c0-5.7-2.3-11.2-6.3-15.2zm.1 8.9c-.1.4-.4.7-.7.9L18.2 33.4c-.4.3-.9.3-1.3.2-.5-.1-.9-.4-1.1-.7l-5.9-8.7-.3-.6c0-.2-.1-.5 0-.7 0-.2.1-.4.3-.6s.3-.4.5-.5l.6-.3h.7c.2 0 .4.1.6.3.2.1.4.3.5.5l4.9 7.2 20-13c.3-.2.6-.3.9-.3.4 0 .8.1 1.1.3.3.2.5.6.7.9.1.5.1.9-.1 1.3z" />
			</symbol>
			<symbol id="icon--edit" viewBox="0 0 50 50">
				<path d="M25 50C11.2 50 0 38.8 0 25S11.2 0 25 0s25 11.2 25 25-11.2 25-25 25zm0-47.7C12.5 2.3 2.3 12.5 2.3 25S12.5 47.7 25 47.7 47.7 37.5 47.7 25 37.5 2.3 25 2.3zm-3.4 31.9c-.5.5-1 .8-1.7.9l-5.2 1.2c-.3.1-.6 0-.8-.2-.2-.2-.3-.5-.2-.8l1.2-5.2c.2-.6.5-1.2.9-1.7l13.6-13.6c1.6-1.6 4.2-1.6 5.8 0 1.6 1.6 1.6 4.2 0 5.8L21.6 34.2zm6.8-15.9L17 29.6c-.2.2-.4.5-.5.9l-.9 3.9 3.9-.9c.3-.1.6-.2.9-.5l11.4-11.4-3.4-3.3zM34 16c-.9-.9-2.4-.9-3.3 0l-1 1 3.3 3.3 1-1c.9-.8.9-2.3 0-3.3z" id="Layer_2" />
			</symbol>
			<symbol id="icon--web" viewBox="0 0 50 50">
				<path d="M25 0C11.2 0 0 11.2 0 25s11.2 25 25 25 25-11.2 25-25S38.8 0 25 0zm16.4 9.6c-2.5.9-5.1 1.5-7.8 1.9-.8-3.4-2-6.4-3.5-8.4 4.3 1 8.2 3.2 11.3 6.5zm-24 14.2c0-3.2.4-6.4 1-9.5 2.2.2 4.4.3 6.6.4 2.2 0 4.4-.1 6.6-.4.6 3.1.9 6.3 1 9.5H17.4zm15.2 2.4c0 3.2-.4 6.4-1 9.5-4.4-.5-8.8-.5-13.2 0-.6-3.1-.9-6.3-1-9.5h15.2zM25 2.5c2.2 0 4.6 3.5 6.1 9.3-4 .4-8.1.4-12.1 0 1.4-5.8 3.8-9.3 6-9.3zm-5 .6c-1.5 2.1-2.7 5-3.5 8.4-2.7-.4-5.3-1-7.8-1.9 3-3.3 7-5.5 11.3-6.5zM6.9 11.6c2.9 1.1 5.9 1.9 9 2.3-.6 3.2-.9 6.5-1 9.8H2.5c.3-4.3 1.8-8.6 4.4-12.1zm0 26.8c-2.6-3.5-4.1-7.8-4.4-12.2h12.4c.1 3.3.4 6.6 1 9.8-3.1.5-6.1 1.3-9 2.4zm1.7 2c2.5-.9 5.2-1.5 7.8-1.9.8 3.4 2 6.4 3.5 8.4-4.3-1-8.2-3.2-11.3-6.5zM25 47.5c-2.2 0-4.6-3.5-6.1-9.3 4-.4 8.1-.4 12.1 0-1.4 5.8-3.8 9.3-6 9.3zm5-.6c1.5-2.1 2.7-5 3.5-8.4 2.7.4 5.3 1 7.8 1.9-3 3.2-6.9 5.5-11.3 6.5zm13.1-8.5c-2.9-1.1-5.9-1.9-9-2.3.6-3.2.9-6.5 1-9.8h12.4c-.3 4.3-1.8 8.6-4.4 12.1zm4.3-14.6H35.1c-.1-3.3-.4-6.6-1-9.8 3.1-.4 6.1-1.2 9-2.3 2.6 3.4 4.1 7.7 4.3 12.1z" />
			</symbol>
			<symbol id="icon--phone" viewBox="0 0 50 50">
				<path d="M47.2 24.9c-.7 0-1.2-.5-1.2-1.2 0-4.9-1.8-9.7-5.5-13.6-3.8-4-8.9-6.1-14.1-6.3-.7 0-1.2-.6-1.2-1.2 0-.7.6-1.2 1.2-1.2 5.8.2 11.5 2.6 15.7 7 4.1 4.3 6.1 9.7 6.1 15.2.2.7-.3 1.3-1 1.3zM26.4 7.1c4.3.1 8.6 1.9 11.7 5.3 3 3.2 4.5 7.3 4.6 11.3 0 .7-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2c0-3.5-1.3-7-3.9-9.7-2.7-2.9-6.4-4.4-10.1-4.5-.7 0-1.2-.6-1.2-1.2 0-.7.6-1.2 1.3-1.2zm-.2 5.8c2.8.1 5.6 1.2 7.7 3.4 2 2.1 3 4.7 3 7.4 0 .7-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2c0-2.1-.8-4.1-2.3-5.8-1.6-1.7-3.8-2.6-6-2.7-.7 0-1.2-.6-1.2-1.2s.5-1.1 1.2-1.1zm-8.7 5.7c-.2.3-.3 1.5-.2 1.8 2.4 5.9 6.5 9.8 12.2 12.3.3.1 1.5.1 1.8-.2l7.1-4.3c1.2-.7 2.9-.5 3.9.5l5.4 5.5c2.9 3 3.1 7.7.2 10.5l-3.7 3.5c-1.5 1.4-3.3 1.8-5.1 1.6-1.8-.2-3.5-1-5.2-1.8-14.4-7.3-25-18.3-32.1-32.4C1 13.9.3 12.2.1 10.5c-.2-1.8.3-3.6 1.8-5L5.5 2C6.9.6 8.9 0 10.8 0c1.9.1 3.8.9 5.3 2.4l5.4 5.5c1 1 1.2 2.6.4 3.8l-4.4 6.9zm2.4-9.2-5.4-5.5c-1.1-1.1-2.4-1.6-3.8-1.7-1.3 0-2.6.4-3.6 1.4L3.4 7.1C2.4 8 2.2 9 2.3 10.3c.1 1.3.7 2.8 1.5 4.4C10.7 28.5 20.9 39 34.9 46.2c1.6.8 3.1 1.4 4.4 1.6 1.3.2 2.3-.1 3.3-1l3.7-3.5c2-1.9 1.9-5.2-.2-7.3l-5.4-5.5c-.3-.3-.7-.4-1.1-.1l-7.1 4.3c-1.2.7-2.6.9-3.9.3-6.1-2.7-10.7-7.1-13.4-13.5-.5-1.3-.3-2.7.4-3.8l4.4-7c.2-.6.2-1-.1-1.3z" />
			</symbol>
			<symbol id="icon--mail" viewBox="0 0 50 50">
				<path d="M45.1 8.2H4.9C2.2 8.2 0 10.4 0 13.1v23.7c0 2.7 2.2 4.9 4.9 4.9h40.2c2.7 0 4.9-2.2 4.9-4.9V13.1c0-2.7-2.2-4.9-4.9-4.9zm2.5 28.7c0 1.4-1.1 2.5-2.4 2.5H4.9c-1.3 0-2.4-1.1-2.4-2.5V13.1c0-1.4 1.1-2.5 2.4-2.5h40.2c1.3 0 2.4 1.1 2.4 2.5v23.8zM42.9 16l-11.6 9 11.6 9c.5.4.6 1.2.2 1.7-.2.3-.6.5-1 .5-.3 0-.5-.1-.7-.3l-12-9.4-3.6 2.8c-.4.3-1.1.3-1.5 0l-3.6-2.8L8.6 36c-.2.2-.5.3-.7.3-.7 0-1.2-.5-1.2-1.2 0-.4.2-.7.5-1l11.6-9L7.1 16c-.6-.4-.7-1.1-.3-1.7.4-.6 1.1-.7 1.7-.3l.1.1L25 26.8 41.4 14c.5-.5 1.3-.4 1.7.1.4.5.4 1.3-.1 1.7 0 .1-.1.1-.1.2z" />
			</symbol>
			<symbol id="icon--share" viewBox="0 0 50 50">
				<path d="M39.3 28.6c-3.7 0-7.1 1.9-9.1 5l-9.5-4.7c.5-1.2.7-2.5.7-3.9 0-.8-.1-1.6-.3-2.4l9.6-5.5c3.6 4.7 10.3 5.6 15 2.1 4.7-3.5 5.7-10.2 2.1-14.9-3.6-4.7-10.3-5.6-15-2.1-2.7 2-4.3 5.2-4.3 8.5 0 1.1.2 2.2.5 3.3l-9.3 5.3C16.6 14.4 10 12.9 5 16s-6.5 9.7-3.4 14.7 9.8 6.5 14.8 3.4c.9-.6 1.7-1.3 2.4-2.1l10 5c-.2.8-.3 1.5-.3 2.3 0 5.9 4.8 10.7 10.7 10.7 5.9 0 10.7-4.8 10.7-10.7.1-6-4.7-10.7-10.6-10.7zm0-24.9c3.9 0 7.1 3.2 7.1 7.1s-3.2 7.1-7.1 7.1c-3.9 0-7.1-3.2-7.1-7.1s3.1-7.1 7.1-7.1zM10.7 32.1c-3.9 0-7.1-3.2-7.1-7.1s3.2-7.1 7.1-7.1c3.9 0 7.1 3.2 7.1 7.1s-3.1 7.1-7.1 7.1zm28.6 14.2c-3.9 0-7.1-3.2-7.1-7.1 0-3.9 3.2-7.1 7.1-7.1 3.9 0 7.1 3.2 7.1 7.1 0 3.9-3.2 7.1-7.1 7.1z" />
			</symbol>
		</g>
	</svg>
</template>
<script>
    export default {
        name: 'Icons'
    }
</script>
<style lang="scss">
	#icons { display: none; }
</style>
