<template>
	<main id="redeem" class="redeem page">
		<section class="redeem__intro">
			<span class="redeem__intro__title">
				Crea la tua <br />
				Digital Business Card
			</span>
			<span class="redeem__intro__text">
				&#200; semplicissimo!
			</span>
		</section>
		<section class="redeem__list">
			<span class="redeem__list__item">
				<span class="redeem__list__item__num">
					01.
				</span>
				<span class="redeem__list__item__label">
					Inserisci la tua email aziendale
				</span>
			</span>
			<span class="redeem__list__item">
				<span class="redeem__list__item__num">
					02.
				</span>
				<span class="redeem__list__item__label">
					Conferma l'email
				</span>
			</span>
			<span class="redeem__list__item">
				<span class="redeem__list__item__num">
					03.
				</span>
				<span class="redeem__list__item__label">
					Attiva la tua Digital Business Card!
				</span>
			</span>
		</section>
		<form id="redeem__form" class="redeem__form form">
			<label for="email">
				<small class="form__label">
					Inserisci la tua email
				</small>
				<input type="email" id="email" class="form__input" autocapitalize="true" v-model="form.email" />
				<small v-if="emailerror" class="form__error">
					Il formato della mail non è corretto.
				</small>
			</label>
			<label class="form__checkbox" for="privacy">
				<input type="checkbox" id="privacy" class="form__checkbox__input" v-model="form.privacy" />
				<span class="form__checkbox__fake"></span>
				<span class="form__checkbox__label" v-html="$t('redeem.privacy')"></span>
			</label>
			<span class="form__btn btn filled" v-html="$t('redeem.btn')" :class="{ 'enable': form.privacy }" @click="confirmEmail"></span>
		</form>
		<div v-if="openConfirm" class="overlay" :class="{ 'show': openConfirm }">
			<div class="overlay__box">
				<span class="overlay__box__content">
					<span class="overlay__box__content__title">
						Conferma
					</span>
					<span class="overlay__box__content__text">
						Manderemo la mail di conferma all'indirizzo:
					</span>
					<strong>
						{{ form.email }}
					</strong>
				</span>
				<span class="overlay__box__btn btn filled" @click="redeemCode">
					Invia
				</span>
				<span class="overlay__box__btn btn outline" @click="closeConfirmEmail">
					Indietro
				</span>
			</div>
		</div>
	</main>
</template>
<script>
import Spinner from '@/views/components/common/Spinner';

export default {
	name: 'Redeem',
	components: {
		Spinner,
	},
	data() {
		return {
			code: null,
			emailerror: false,
			openConfirm: false,
			form: {
				email: null,
				privacy: false
			},
		}
	},
	methods: {
		validEmail (email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		closeConfirmEmail() {
			this.openConfirm = false;
		},
		confirmEmail() {
			let isEmailOk = this.validEmail(this.form.email);

			console.log('Is Email Ok?', isEmailOk);
			console.log('FORM', this.form);
			if(isEmailOk) {
				this.openConfirm = true;
				this.emailerror = false;
			} else {
				this.emailerror = true;
			}
		},
		redeemCode() {
			console.log('CODE REDEEMED');
			this.$service.login.login(this.code, this.form.email).then(res => {
				console.log('RES', res);
				this.closeConfirmEmail();
				this.$router.push({ name: 'confirmed.page', query: { email: this.form.email} });
			})

		},
		init() {
			if(this.$route && this.$route.query && this.$route.query.id) {
				this.code = this.$route.query.id;
				console.log('PAGE', this.code);
			}
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/redeem';
</style>
