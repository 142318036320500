import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import api from './plugins/api';
import service from './plugins/service';

import './registerServiceWorker'

import VueEventBus from "vue-plugin-event-bus";

Vue.config.productionTip = false
Vue.use(api);
Vue.use(service);
Vue.use(VueEventBus);

new Vue({
	i18n,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
