<template>
	<span class="customphone">
		<div class="aselect">
			<div class="selector" @click="toggle()">
				<div class="label">
					{{ info.type }}
				</div>
				<div class="arrow" :class="{ 'expanded': visible }"></div>
				<div :class="{ 'hidden': !visible }">
					<ul>
						<li @click="select('voice')">
							voice
						</li>
						<li @click="select('cell')">
							cell
						</li>
						<li @click="select('fax')">
							fax
						</li>
						<li @click="select('video')">
							video
						</li>
						<li @click="select('pager')">
							pager
						</li>
						<li @click="select('text')">
							text
						</li>
						<li @click="select('textphone')">
							textphone
						</li>
					</ul>
				</div>
			</div>
		</div>
		<input type="text" name="tel" :id="`tel_${index}`" class="form__input" v-model="info.value" />
		<small class="customphone__remove" @click="removeData">
			Elimina
		</small>
	</span>
</template>
<script>
import { EventBus } from '@/events_bus.js';

export default {
	name: 'CustomPhone',
	props: {
		index: {
			type: Number,
			required: true,
		},
		info: {
			type: Object,
			required: true,
		},
		inputType: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			visible: false,
		}
	},
	methods: {
		removeData() {
			EventBus.$emit('remove-data', {type: this.inputType, index: this.index})
		},
		toggle() {
			this.visible = !this.visible;
		},
		select(option) {
			this.info.type = option;
		},
		init() {
			if(this.info.type == '') {
				this.info.type = 'voice'
			}
		}
	},
	mounted() {
		if(this.info) {
			this.init();
			console.log('INFO', this.info);
		}
	}
}
</script>
<style lang="scss">
.customphone {
	@extend %flexCenterBetween;
	flex-wrap: wrap;
	margin-bottom: var(--thirdgutter);
	padding-bottom: 20px;
	position: relative;
	.aselect {
		@include size(100px, var(--input));
		.selector {
			display: block;
			@include size(100%,100%);
			border: 1px solid $color-white;
			background: $color-darkpurple;
			position: relative;
			z-index: 1;
			border-radius: var(--inputradius);
			.arrow {
				position: absolute;
				top: 50%;
				right: var(--minigutter);
				display: block;
				@include size(var(--minigutter),var(--minigutter));
				@extend %arrowClose;
				transform: translateY(-50%);
				transition: 180ms linear;
				pointer-events: none;
				&:before,
				&:after {
					background: $color-white;
					@include size(60%,2px);
				}
				&:before {
					margin-left: 3px;
				}
				&:after {
					margin-left: -3px;
				}
			}
			.expanded {
				transform: scaleY(-1) translateY(50%);
			}
			.label {
				@extend %flexCenterStart;
				@include size(100%,100%);
				padding: 12px;
				font-size: 16px;
			}
		}
		ul {
			width: 100%;
			list-style-type: none;
			padding: 0;
			margin: 0;
			font-size: 16px;
			border: 1px solid $color-white;
			position: absolute;
			z-index: 1;
			background: $color-darkpurple;
		}
		li {
			@extend %flexCenterStart;
			width: 100%;
			padding: 6px 15px;
			color: $color-white;
			list-style: none;
			&:before {
				display: none;
			}
		}
		.hidden {
			visibility: hidden;
		}
		.visible {
			visibility: visible;
		}
	}
	.form__input {
		width: calc(100% - 106px) !important;
		margin-left: 6px;
		margin: 0 !important;
	}
	&__remove {
		position: absolute;
		bottom: 0;
		right: 0;
		width: max-content;
		color: lighten($color-error, 15%);
		@extend %text-12;
		text-transform: uppercase;
	}
}
</style>
